<template>
  <div class="embed-page">
<!--    <pageTitle pageTitle="嵌入页"></pageTitle>-->
    <iframe
      v-if="embedUrl"
      class="embed-page-iframe"
      :src="embedUrl"
      width="100%"
      frameborder="0"
      allow="camera *;microphone *; display-capture"
      allowfullscreen="true"
      border="0"
    ></iframe>
    <EmbedPageError v-else></EmbedPageError>
  </div>
</template>

<script>
  // import PageTitle from '@/components/PageTitle';
  import EmbedPageError from './embedPageError.vue'
  export default {
    name: 'EmbedPage',
    components:{
      EmbedPageError,
    },
    data() {
      return {
        embedUrl: '',
        // embed404: '/live/error/404/404',
        type: this.$route.params.type,
        index: this.$route.params.index
      };
    },
    // components: {
    //   PageTitle,
    // },
    created() {
      this.init()
    },
    methods: {
      init() {
        const config = localStorage.getItem('VH_DEP_CONSOLE_THIRD_EMBED_PAGE_CONFIG')
        if(config){
          try {
            const configObj = JSON.parse(config)
            switch (this.type){
              case 'data':
                this.handleDataModule(configObj['data.third_embed_page'] || [])
                break
            }
          }catch (e) {
            console.error(e)
            this.embedUrl = ''
          }
        }
      },
      handleDataModule(list=[]) {
        if(list.length > 0){
          this.embedUrl = list[this.index]?.url || ''
        }
      }
    }
  };
</script>

<style lang="less" scoped>
.embed-page {
  .embed-page-iframe{
    height: calc(100vh - 56px);
    width: 100%;
  }
}
</style>
